<template>
  <Section id="AwardsSection" opacity="1" :title="$t('awards-section.title')" img="./img/backgrounds/bg-premios.jpg">
    <div v-appear class="wrapper">
      <img src="img/awards/banner-selos-fundimisa-2025.png" alt="Imagem premiações">
    </div>
  </Section>
</template>

<script>
  import components from '@/components/global'
  const { Section } = components;
  
  export default {
    components: {
      Section,
    },
    computed: {
      img() {
        return './img/awards/premios' + (this.vert ? '-vert': '') + (this.$i18n.locale === 'en' ? '-en' : '') + '.png'
      },
      alt() {
        return this.$i18n.locale === 'en' ? 'Image Awards' : "Imagem Premios"
      }
    },
    data() {
      return {
        vert: window.innerWidth < 900
      }
    },
    created() {
      window.addEventListener("resize", this.changeOrientation);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeOrientation);
    },
    methods: {
      changeOrientation(e) {
        this.vert = e.target.innerWidth < 900
      }
    },

  }
</script>

<style lang="css" scoped>

  .wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .wrapper img {
    width: 100%;
  }
</style>